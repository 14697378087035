<template>
  <div class="success-payment-container">
    <div class="success-payment-container__success-payment">
      <img
          src="@/assets/icons/payment-success-icon.svg"
          alt="Payment success"
          class="success-payment-container__success-payment--image"
      />
      <h2 class="success-payment-container__success-payment--title">
        Payment Successful
      </h2>
      <p class="success-payment-container__success-payment--description">
        Please click continue to access your <br/>
        News SuperIntelligence environment.
      </p>
      <a-button
          @click="redirectToDiscover"
          class="success-payment-container__success-payment--btn"
      >
        Continue
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToDiscover() {
      this.$router.push("/discover");
    },
  },
};
</script>

<style lang="scss" scoped>
.success-payment-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #3737370d;
  width: 100%;

  &__success-payment {
    min-width: 50rem;
    background-color: $color-white;
    padding: 6rem 3rem;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;

    &--image {
      margin-bottom: 6rem;
      width: auto;
    }

    &--title {
      font-size: 2.8rem;
      font-family: $font-primary-bold;
      margin-bottom: 1.8rem;
      line-height: normal;
    }

    &--description {
      font-size: 1.6rem;
      font-family: $font-primary;
      opacity: 0.7;
      line-height: 2.2rem;
    }

    &--btn {
      margin-top: 6rem;
      width: 14rem;
      height: 4rem;
      background-color: $color-primary;
      border: none;
      color: $color-white;
      font-size: 1.6rem;
      font-family: $font-primary-bold;

      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
}
</style>
